import React from 'react'
import { cn } from '~/utils/misc'

interface ChipProps {
	text: React.ReactNode
	color: string
	className?: string
	title?: string
	size?: 'xs' | 'sm' | 'md'
}
interface ColorMap {
	[key: string]: string
}
export const categoryColors: ColorMap = {
	orange: 'orange',
	green: 'green',
	yellow: 'yellow',
	pink: 'pink',
	red: 'red',
	amber: 'amber',
	lime: 'lime',
	emerald: 'emerald',
	teal: 'teal',
	cyan: 'cyan',
	sky: 'sky',
	indigo: 'indigo',
	violet: 'violet',
	purple: 'purple',
	fuchsia: 'fuchsia',
	rose: 'rose',
	slate: 'slate',
	secondary: 'secondary',
	card: 'card',
	cardActive: 'card-active',
}

export default function Chip({
	text,
	color,
	className,
	title,
	size = 'sm',
}: ChipProps) {
	const bgColor =
		categoryColors[color] == 'secondary'
			? 'bg-secondary/90'
			: categoryColors[color] == 'card'
				? 'bg-card !text-card-foreground'
				: categoryColors[color] == 'card-active'
					? 'bg-foreground/10 !text-card-foreground border border-foreground/50'
					: `bg-${categoryColors[color]}-700`
	const sizeClasses =
		size === 'xs'
			? 'px-2 py-1 text-xs rounded-full'
			: size === 'sm'
				? 'px-4 py-2 md:py-1.5 md:px-2.5 text-xs rounded-xl font-semibold'
				: 'px-3 py-2 text-sm rounded-full'
	const classes = cn(
		className,
		`center relative inline-block select-none self-start whitespace-nowrap ${sizeClasses} ${bgColor} align-baseline font-sans leading-none text-white`,
	)
	return (
		<div className={classes} title={title}>
			{text}
		</div>
	)
}
